import { MutationFunction, QueryFunction } from '@tanstack/react-query';
import {
  OrderDto,
  OrderDocumentsResponseDto,
  ManualApproveDataDto,
  OsfAddressDto,
} from 'ventas-digitales-v2-common';
import api from './api';
import { parseQuery } from '../utils/http';

export const getOrdersList: MutationFunction<
  OrderDto[],
  { page: number; limit: number; sort?: string; filters?: string }
> = async (data) => {
  const response = await api.get<OrderDto[]>(`orders?${parseQuery(data)}`, {
    data,
  });
  return response.data;
};

export const getOrder: QueryFunction<OrderDto, [string, string]> = async ({
  queryKey,
}) => {
  const [, orderId] = queryKey;
  const response = await api.get<OrderDto>(`orders/${orderId}`, {});
  return response.data;
};

export const updateOrderStatus: MutationFunction<
  unknown,
  {
    orderId: string;
    type: 'contractor' | 'functional';
    approved: boolean;
    comment?: string;
  }
> = (data) => {
  const { orderId, type, ...payload } = data;
  return api.put<unknown>(`orders/${orderId}/validate-${type}`, payload);
};

export const updateFailedOrderStatus: MutationFunction<
  unknown,
  {
    orderId: string;
    comment: string;
  }
> = (data) => {
  const { orderId, ...payload } = data;
  return api.put<unknown>(`orders/${orderId}/failed-status`, payload);
};

export const retrySendOsf: MutationFunction<unknown, string> = (orderId) => {
  return api.post<unknown>(`orders/${orderId}/send-osf`);
};

export const retrySendGis: MutationFunction<
  unknown,
  {
    orderId: string;
    comment: string;
  }
> = (data) => {
  const { orderId, ...payload } = data;
  return api.post<unknown>(`addresses/${orderId}/send-gis`, payload);
};

export const closeGis: MutationFunction<unknown, string> = (orderId) => {
  return api.post<unknown>(`addresses/${orderId}/close-gis`);
};

export const manualApprove: MutationFunction<
  unknown,
  [string, ManualApproveDataDto]
> = ([orderId, data]) => {
  return api.put<unknown>(`addresses/${orderId}/manual-approve`, data);
};

export const searchAddressId: QueryFunction<
  OsfAddressDto,
  [string, number]
> = async ({ queryKey }) => {
  const [, addressId] = queryKey;
  const response = await api.get<OsfAddressDto>(`addresses/${addressId}/osf`);
  return response.data;
};

export const getOrderDocuments: QueryFunction<
  OrderDocumentsResponseDto,
  [string, string]
> = async ({ queryKey }) => {
  const [, orderId] = queryKey;
  const response = await api.get<OrderDocumentsResponseDto>(
    `orders/${orderId}/documents`,
    {}
  );
  return response.data;
};

export const addOrderComment: MutationFunction<
  unknown,
  {
    orderId: string;
    comment: string;
  }
> = (data) => {
  const { orderId, ...payload } = data;
  return api.post<unknown>(`orders/${orderId}/comments`, payload);
};
