import { ColDef, FilterModel } from 'ag-grid-community';
import {
  QueryFilterDto,
  QueryFilterOperator,
} from 'ventas-digitales-v2-common';
import { DateTime } from 'luxon';

export const PAGE_SIZE_SELECTOR = [20, 50, 100];

export const DEFAULT_COL: ColDef = {
  menuTabs: [],
  floatingFilter: true,
};

export const NUMERIC_FILTER_PARAMS = {
  allowedCharPattern: /\d/,
  maxNumConditions: 1,
  filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
  buttons: ['clear'],
};

export const TEXT_FILTER_PARAMS = {
  filterOptions: ['contains'],
  maxNumConditions: 1,
  buttons: ['clear'],
};

export const dateFilterParams = () => ({
  maxNumConditions: 1,
  maxValidDate: new Date(),
  filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
  buttons: ['clear'],
});

export const dateSortComparator =
  (fieldName: string): ColDef['comparator'] =>
  (_, __, a, b) => {
    if (!a.data || !b.data) {
      return 0;
    }

    const dateA = a.data[fieldName] as string;
    const dateB = b.data[fieldName] as string;

    return (
      DateTime.fromISO(dateA).toMillis() - DateTime.fromISO(dateB).toMillis()
    );
  };

export const FILTER_TYPE_TO_OPERATOR_MAP: Record<
  string,
  | QueryFilterOperator.Equals
  | QueryFilterOperator.Contains
  | QueryFilterOperator.LessThanOrEqual
  | QueryFilterOperator.GreaterThanOrEqual
> = {
  equals: QueryFilterOperator.Equals,
  contains: QueryFilterOperator.Contains,
  lessThanOrEqual: QueryFilterOperator.LessThanOrEqual,
  greaterThanOrEqual: QueryFilterOperator.GreaterThanOrEqual,
};

const parseDateValue = (date: string, stripToEnd = false) => {
  let dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss');

  if (stripToEnd) {
    dateTime = dateTime.endOf('day');
  }

  return dateTime.toISO()!;
};

export const parseFilters = (filterModel: FilterModel | null) =>
  Object.fromEntries(
    Object.entries(filterModel || {})
      .map(
        ([
          key,
          { filterType, type, filter, filterTo, values, dateFrom, dateTo },
        ]): [string, QueryFilterDto | null] => {
          if (filterType === 'set') {
            return [
              key,
              values.length
                ? { operator: QueryFilterOperator.In, value: values }
                : null,
            ];
          }

          if (filterType === 'number' && type === 'inRange') {
            return [
              key,
              {
                operator: QueryFilterOperator.Between,
                value:
                  filter <= filterTo ? [filter, filterTo] : [filterTo, filter],
              },
            ];
          }

          if (filterType === 'date') {
            switch (type) {
              case 'equals':
                return [
                  key,
                  {
                    operator: QueryFilterOperator.Between,
                    value: [
                      parseDateValue(dateFrom),
                      parseDateValue(dateFrom, true),
                    ],
                  },
                ];
              case 'lessThanOrEqual':
                return [
                  key,
                  {
                    operator: QueryFilterOperator.LessThanOrEqual,
                    value: parseDateValue(dateFrom, true),
                  },
                ];
              case 'greaterThanOrEqual':
                return [
                  key,
                  {
                    operator: QueryFilterOperator.GreaterThanOrEqual,
                    value: parseDateValue(dateFrom),
                  },
                ];
              case 'inRange':
                return [
                  key,
                  {
                    operator: QueryFilterOperator.Between,
                    value:
                      dateFrom < dateTo
                        ? [
                            parseDateValue(dateFrom),
                            parseDateValue(dateTo, true),
                          ]
                        : [
                            parseDateValue(dateTo),
                            parseDateValue(dateFrom, true),
                          ],
                  },
                ];
              default:
                return [key, null];
            }
          }

          const operator = FILTER_TYPE_TO_OPERATOR_MAP[type];
          if (operator) {
            if (
              operator === QueryFilterOperator.Contains &&
              typeof filter === 'string'
            ) {
              const trimmed = filter.trim();
              return [key, trimmed ? { operator, value: trimmed } : null];
            }

            return [key, { operator, value: filter }];
          }

          return [key, null];
        }
      )
      .filter(([, model]) => model)
  );
