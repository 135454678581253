import {
  PropertyActionType,
  ClientType,
  CommercialPlan,
  GisAddressRequestStatus,
  OrderStatus,
  QuotationType,
  CouponStatus,
  OrderAction,
  OrderHistoryMetadata,
  EvidenceTypeName,
  RiskType,
  CategoryType,
  CommercialSubcategoryType,
  ResidentialSubcategoryType,
} from 'ventas-digitales-v2-common';
import { DateTime } from 'luxon';

const locale: {
  agGrid: Record<string, string>;
  quotationTypeEnum: Record<QuotationType, string>;
  clientTypeEnum: Record<ClientType, string>;
  orderStatusEnum: Record<OrderStatus, string>;
  propertyActionTypeEnum: Record<PropertyActionType, string>;
  gisRequestStatusEnum: Record<GisAddressRequestStatus, string>;
  commercialPlanEnum: Record<CommercialPlan, string>;
  couponStatusEnum: Record<CouponStatus, string>;
  orderActionEnum: Record<OrderAction, string>;
  orderActionMetas: Record<keyof OrderHistoryMetadata, string>;
  notificationTemplateNames: Record<
    Required<OrderHistoryMetadata>['template'],
    string
  >;
  evidenceNames: Record<EvidenceTypeName, string>;
  riskTypeEnum: Record<RiskType, string>;
  categoryTypeEnum: Record<CategoryType, string>;
  commercialSubcategoryEnum: Record<CommercialSubcategoryType, string>;
  residentialSubcategoryEnum: Record<ResidentialSubcategoryType, string>;
} = {
  agGrid: {
    copy: 'Copiar',
    export: 'Exportar',
    csvExport: 'CSV',
    excelExport: 'Excel',
    of: 'de',
    to: 'hasta',
    page: 'Página',
    pageSizeSelectorLabel: 'Ventas por página',
    more: 'muchas',
    equals: 'Igual',
    lessThanOrEqual: 'Menor o igual',
    greaterThanOrEqual: 'Mayor o igual',
    inRange: 'Entre',
    selectAll: 'Seleccionar todo',
    contains: 'Contiene',
    applyFilter: 'Filtrar',
    clearFilter: 'Limpiar',
    autosizeAllColumns: 'Ajustar tabla',
    noRowsToShow: 'No hay datos para mostrar',
  },
  quotationTypeEnum: {
    [QuotationType.Commercial]: 'Comercial',
    [QuotationType.Residential]: 'Residencial',
    [QuotationType.SpecialCommercial]: 'Comercial especial',
    [QuotationType.SpecialResidential]: 'Residencial Especial',
  },
  orderStatusEnum: {
    [OrderStatus.Deassigned]: 'No asignada',
    [OrderStatus.Pending]: 'Pendiente',
    [OrderStatus.InProgress]: 'En progreso',
    [OrderStatus.Signed]: 'Firmada',
    [OrderStatus.Completed]: 'Completada',
    [OrderStatus.Closed]: 'Cerrada',
    [OrderStatus.PendingGIS]: 'Predio en revisión',
    [OrderStatus.RejectedGIS]: 'Predio a subsanar',
    [OrderStatus.PendingGIS2]: 'Segunda revisión de predio',
    [OrderStatus.ClosedGIS]: 'Predio rechazado',
    [OrderStatus.PendingContractor]: 'Revisión por contratista',
    [OrderStatus.RejectedContractor]: 'Rechazado por contratista',
    [OrderStatus.PendingCertificate]: 'Revisión de certificado',
    [OrderStatus.PendingValidation]: 'Revisión de subsidio',
    [OrderStatus.RejectedValidation]: 'Subsidios rechazados',
    [OrderStatus.PendingCreation]: 'Creando en OSF',
    [OrderStatus.Created]: 'Creada en OSF',
    [OrderStatus.CreationError]: 'Error en OSF',
    [OrderStatus.Failed]: 'Finalizado con fallo',
  },
  clientTypeEnum: {
    [ClientType.NaturalPerson]: 'Persona Natural',
    [ClientType.LegalPerson]: 'Persona Jurídica',
  },
  propertyActionTypeEnum: {
    [PropertyActionType.NewProperty]: 'Crear nuevo predio',
    [PropertyActionType.MakePropertyIndependent]: 'Independizar predio',
    [PropertyActionType.CorrectProperty]: 'Corregir predio',
    [PropertyActionType.UseProperty]: 'Usar esta dirección',
  },
  gisRequestStatusEnum: {
    [GisAddressRequestStatus.Pending]: 'Pendiente',
    [GisAddressRequestStatus.Accepted]: 'Aceptado',
    [GisAddressRequestStatus.Rejected]: 'Rechazado',
    [GisAddressRequestStatus.Skipped]: 'Omitida',
    [GisAddressRequestStatus.ManuallyAccepted]: 'Validación Manual',
  },
  commercialPlanEnum: {
    [CommercialPlan.ResidentialFullPackage]:
      'CARGO X CONEXION-INT INTERNA CON REVISION PREVIA',
    [CommercialPlan.ResidentialInternalConnectionFee]:
      'CARGO POR CONEXION-INSTALACION INTERNA SIN REVISION PREVIA',
    [CommercialPlan.ResidentialCertifiedConnectionFee]:
      'CXC CON REV INT X F.INDP',
    [CommercialPlan.ResidentialConnectionFee]: 'CXC SIN REV INT X F.INDP',
    [CommercialPlan.CommercialFullPackage]:
      'VENTA COMERCIAL CONTRIB PAQUETE COMPLETO',
    [CommercialPlan.CommercialCertifiedConnectionFee]:
      'VTA COT COMERC CXC CON CERT',
    [CommercialPlan.CommercialConnectionFee]: 'VTA COMERC CONTR CXC SIN CER',
    [CommercialPlan.SpecialResidentialConnectionFee]:
      'VTA COT RESID CXC SIN CERTIF',
    [CommercialPlan.SpecialResidentialFullPackage]:
      'VTA COT RESID PAQ COMPLETO',
    [CommercialPlan.SpecialResidentialCertifiedConnectionFee]:
      'VTA COT RESID CXC CON CERTIF',
  },
  couponStatusEnum: {
    [CouponStatus.Generated]: 'Generado',
    [CouponStatus.Sent]: 'Enviado',
    [CouponStatus.Paid]: 'Pagado',
  },
  orderActionEnum: {
    [OrderAction.StatusChanged]: 'Cambio de estado',
    [OrderAction.SendGis]: 'Envío de predio a GIS',
    [OrderAction.ResendGis]: 'Reenvío de predio a GIS',
    [OrderAction.ReceiveGis]: 'Respuesta de GIS recibida',
    [OrderAction.ManualPropertyApprove]: 'Aprobación manual de predio',
    [OrderAction.SendCertificate]: 'Envío de certificado al portal cert.',
    [OrderAction.ReceiveCertificate]: 'Respuesta del portal cert. recibida',
    [OrderAction.SendOsf]: 'Envío de venta a OSF',
    [OrderAction.ResendOsf]: 'Reenvío de venta a OSF',
    [OrderAction.CreateCoupon]: 'Generación de cupón',
    [OrderAction.RecreateCoupon]: 'Regeneración de cupón',
    [OrderAction.SendCoupon]: 'Envío de cupón a usuario',
    [OrderAction.ResendCoupon]: 'Reenvío de cupón a usuario',
    [OrderAction.NotifyNoPayment]: 'Notificación de cuota cero al portal cert.',
    [OrderAction.ReceivePayment]: 'Pago del cupón recibido',
    [OrderAction.SendPayment]: 'Notificación de pago recibido al portal cert.',
    [OrderAction.SendOnbase]: 'Envío de documento a OnBase',
    [OrderAction.UserNotification]: 'Notificación enviada',
    [OrderAction.UpdateContractorStatus]: 'Validación contratista',
    [OrderAction.UpdateValidationStatus]: 'Validación subsidios',
    [OrderAction.RecalculateFinancingValues]:
      'Valores de financiación recalculados',
  },
  orderActionMetas: {
    addressId: 'Código asignado',
    approved: 'Aprobado',
    cellphoneNumber: 'Teléfono',
    couponNumber: 'No. de cupón',
    email: 'Correo',
    status: 'Estado',
    template: 'Plantilla',
    newInterestRate: 'Nueva tasa de interés',
    oldInstallmentValue: 'Antigua cuota mensual',
    newInstallmentValue: 'Nueva cuota mensual',
    initialStatus: 'Estado inicial',
  },
  notificationTemplateNames: {
    'sale-created-email': 'Inicio proceso venta',
    'sale-created-sms': 'Inicio proceso venta',
    'sale-closed-email': 'Venta cerrada',
    'sale-closed-sms': 'Venta cerrada',
    'sale-finished-email': 'Venta finalizada',
    'sale-finished-sms': 'Venta finalizada',
    'sale-closed-contractor-email': 'Venta cerrada contratista',
    'send-gis-email': 'Predio enviado a GIS',
  },
  evidenceNames: {
    [EvidenceTypeName.FaceEvidence]: 'Foto del Cliente',
    [EvidenceTypeName.BackIdentificationEvidence]: 'Reverso de la Cédula',
    [EvidenceTypeName.FrontIdentificationEvidence]: 'Anverso de la Cédula',
    [EvidenceTypeName.CertificationEvidence]: 'Certificación',
    [EvidenceTypeName.InternalInstallationDesign]: 'Diseño de la Interna',
    [EvidenceTypeName.InternalInstallationEvidence]: 'Instalación Interna',
    [EvidenceTypeName.PropertyEvidence]: 'Foto del Predio',
    [EvidenceTypeName.SubsidyAcceptanceEvidence]:
      'Carta de Aceptación del Subsidio',
    [EvidenceTypeName.StratumEvidence]: 'Certificado de Estrato del Predio',
  },
  riskTypeEnum: {
    [RiskType.FreeRisk]: 'Sin riesgo',
    [RiskType.LowRisk]: 'Riesgo bajo',
    [RiskType.MediumRisk]: 'Riesgo medio',
    [RiskType.HighRisk]: 'Riesgo alto',
    [RiskType.SuperHighRisk]: 'Riesgo muy alto',
  },
  categoryTypeEnum: {
    [CategoryType.Residential]: 'Residencial',
    [CategoryType.Commercial]: 'Comercial',
  },
  commercialSubcategoryEnum: {
    [CommercialSubcategoryType.Special]: 'Especial',
    [CommercialSubcategoryType.FixedFactor]: 'Factor fijo',
    [CommercialSubcategoryType.Official]: 'Oficial',
    [CommercialSubcategoryType.OnlyComercial]: 'Único',
  },
  residentialSubcategoryEnum: {
    [ResidentialSubcategoryType.Stratum1]: 'Estrato 1',
    [ResidentialSubcategoryType.Stratum2]: 'Estrato 2',
    [ResidentialSubcategoryType.Stratum3]: 'Estrato 3',
    [ResidentialSubcategoryType.Stratum4]: 'Estrato 4',
    [ResidentialSubcategoryType.Stratum5]: 'Estrato 5',
    [ResidentialSubcategoryType.Stratum6]: 'Estrato 6',
  },
};

export const localizeDateTime = (
  date: string | undefined | null,
  hour24 = false
) => {
  if (!date) {
    return '-';
  }

  const dateTime = DateTime.fromISO(date)
    .setLocale('es-CO')
    .setZone('America/Bogota');

  if (hour24) {
    return dateTime.toFormat('dd/MM/yyyy, HH:mm:ss');
  }

  return dateTime.toFormat('dd/MM/yyyy, hh:mm:ss a');
};

export const localizeList = (list: any[] | null | undefined) => {
  if (!list?.length) {
    return '-';
  }

  if (list.length === 1) {
    return list[0];
  }

  return [list.slice(0, -1).join(', '), list[list.length - 1]].join(' y ');
};

export default locale;
